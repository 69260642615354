import { api } from '@/api';
import { ILandingOrder, INewOrder, ISignUp } from '@/interfaces';
import { i18n } from '@/main';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetAppConfigs,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetOrdersList,
    commitSetOutsideHeaderMobileMenuOpen,
    commitSetToken,
    commitSetUserProfile,
} from './mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string, code: string, timezone_name?: any }) {
        try {
            const response = await api.submitlogIn(payload);
            const token = response.data.token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                // commitSetLogInError(context, false);
                try {
                    const response = await api.getMe(token);
                    if (response.data) {
                        commitSetUserProfile(context, response.data);
                    }
                } catch (error) {
                    // await dispatchCheckApiError(context, error);
                }
                // await dispatchRouteLoggedIn(context);
                // commitAddNotification(context, { content: 'Logged in', color: 'success' });
                return true;
            } else {
                await dispatchLogOut(context);
                return false;
            }
        } catch (err) {
            // commitSetLogInError(context, true);
            await dispatchLogOut(context);
            return false;
        }
    },
    async actionSubmitLandingOrder(context: MainContext, payload: ILandingOrder) {
        try {
            const response = await api.submitLandingOrder(payload);
            const token = response.data.auth_data.token;
            saveLocalToken(token);
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            commitSetUserProfile(context, response.data.user);
            // await dispatchGetUserProfile(context);
            return response;
        } catch (err) {
            return err.response;
            // await dispatchLogOut(context);
            return null;
        }
    },
    async actionSubmitNewOrder(context: MainContext, payload: INewOrder) {
        try {
            const response = await api.submitNewOrder(context.state.token, payload);
            return response;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return err.response;
        }
    },
    async actionSubmitSignUp(context: MainContext, payload: ISignUp) {
        try {
            const response = await api.submitSignUp(payload);
            const token = response.data.token;
            saveLocalToken(token);
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            try {
                const response = await api.getMe(token);
                if (response.data) {
                    commitSetUserProfile(context, response.data);
                }
            } catch (error) {
                // await dispatchCheckApiError(context, error);
            }
            return response;
        } catch (err) {
            return err.response;
        }
    },
    async updateUserAvatar(context: MainContext, payload: FormData) {
        try {
            const response = await api.updateUserAvatar(context.state.token, payload);
            commitSetUserProfile(context, response.data);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async unsubscribeFromBot(context: MainContext) {
        try {
            const response = await api.unsubscribeFromBot(context.state.token);
            return true;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return false;
        }
    },
    async actionСheckPromocode(context: MainContext, payload: {referral: string}) {
        try {
            const response = await api.checkPromocode(payload);
            return response.data;
        } catch (err) {
            return null;
        }
    },
    async actionSubmitRecoverPassword(context: MainContext, payload: {email: string}) {
        try {
            const response = await api.submitRecoverPassword(payload);
            return {
                success: true,
            };
        } catch (err) {
            return {
                success: false,
                error: err.response.data.email ? err.response.data.email[0] : 'Email doesn’t exist',
            };
        }
    },
    async actionSubmitResetPassword(context: MainContext, payload: {token: string, password: string}) {
        try {
            const response = await api.submitResetPassword(payload);
            return response.data.status;
        } catch (err) {
            return err.response.data.detail;
        }
    },
    async actionSubmitSetLanguage(context: MainContext, payload: {language: string}) {
        try {
            const response = await api.submitSetLanguage(context.state.token, payload);
            return true;
        } catch (err) {
            return false;
        }
    },
    // async actionGetOrders(context: MainContext) {
    //     try {
    //         const response = await api.getOrdersList(context.state.token);
    //         if (response.data) {
    //             commitSetOrdersList(context, response.data);
    //         }
    //     } catch (error) {
    //         commitSetOrdersList(context, []);
    //         await dispatchCheckApiError(context, error);
    //     }
    // },
    async actionGetProductSentencesSet(context: MainContext, data: {product_sentences_set: string, promocode?: string}) {
        try {
            const response = await api.getProductSentencesSet(context.state.token, data);
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return null;
        }
    },
    async actionSubmitUpdateOrder(
        context: MainContext, data: {order: number, delivery_address?: string, starlink_kit?: boolean, promocode?: string},
    ) {
        try {
            const response = await api.submitUpdateOrder(context.state.token, data);
            return response.data;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return null;
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAllConfigs(context: MainContext) {
        try {
            const response = await api.getAllConfigs(context.state.token);
            commitSetAppConfigs(context, response.data);
        } catch (error) {
            commitSetAppConfigs(context, {});
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload: any) {
        try {
            const response = await api.updateMe(context.state.token, payload);
            commitSetUserProfile(context, response.data);
            return true;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return false;
        }
    },
    async actionUpdateUserInfo(context: MainContext, payload: {code_of_etics_status?: string}) {
        try {
            const response = await api.updateUserInfo(context.state.token, payload);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
            return true;
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return false;
        }
    },
    async actionLogInByToken(context: MainContext, payload: { authToken: string, language: string }) {
        try {
            const response = await api.logInByToken(payload.authToken, payload.language);
            const token = response.data.token;
            if (token) {
                await saveLocalToken(token);
                await commitSetToken(context, token);
                await commitSetLoggedIn(context, true);
                // await commitSetLoggedInByToken(context, true);
                await commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                // commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            // commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        commitSetOutsideHeaderMobileMenuOpen(context, false);
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/' && router.currentRoute.path !== '/login' && router.currentRoute.path !== '/sign-up') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push('/dashboard');
        }
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Resetting password', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    async actionCheckFormError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 400) {
            if (payload.response && payload.response.data) {
                let errorMsg = '';
                // @ts-ignore
                for (const [key, value] of Object.entries(payload.response.data)) {
                    // @ts-ignore
                    errorMsg += `${value[0]}\n`;
                }
                // @ts-ignore
                this._vm.$toast.error(errorMsg);
            } else {
                // @ts-ignore
                this._vm.$toast.error(i18n.t('Error'));
            }
        } else if (payload.response!.status === 500) {
            // @ts-ignore
            this._vm.$toast.error(i18n.t('Server error'));
        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchGetAllConfigs = dispatch(actions.actionGetAllConfigs);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchSubmitRecoverPassword = dispatch(actions.actionSubmitRecoverPassword);
export const dispatchSubmitResetPassword = dispatch(actions.actionSubmitResetPassword);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchUpdateUserInfo = dispatch(actions.actionUpdateUserInfo);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchSubmitLandingOrder = dispatch(actions.actionSubmitLandingOrder);
export const dispatchSubmitNewOrder = dispatch(actions.actionSubmitNewOrder);
export const dispatchSubmitSignUp = dispatch(actions.actionSubmitSignUp);
// export const dispatchGetOrders = dispatch(actions.actionGetOrders);
export const dispatchGetProductSentencesSet = dispatch(actions.actionGetProductSentencesSet);
export const dispatchSubmitUpdateOrder = dispatch(actions.actionSubmitUpdateOrder);
export const dispatchСheckPromocode = dispatch(actions.actionСheckPromocode);
export const dispatchSubmitSetLanguage = dispatch(actions.actionSubmitSetLanguage);
export const dispatchLogInByToken = dispatch(actions.actionLogInByToken);
export const dispatchUpdateUserAvatar = dispatch(actions.updateUserAvatar);
export const dispatchUnsubscribeFromBot = dispatch(actions.unsubscribeFromBot);
export const dispatchCheckFormError = dispatch(actions.actionCheckFormError);
